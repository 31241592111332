export function useScrollDirection({ deltaThreshold, scrollThreshold, disabled = undefined }) {
  const [scrollDirection, setScrollDirection] = React.useState(0)
  const [metScrollThreshold, setMetScrollThreshold] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)

    function handleResize() {
      setScrollDirection(-1)
    }
  })

  React.useEffect(
    () => {
      let scrollY = window.scrollY

      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        if (disabled) return

        const deltaY = window.scrollY - scrollY
        const direction = Math.sign(deltaY)
        const difference = Math.abs(deltaY)
        const metDeltaThreshold = difference >= deltaThreshold
        setMetScrollThreshold(window.scrollY > scrollThreshold)

        if (metDeltaThreshold) {
          scrollY = window.scrollY
        }

        if (metScrollThreshold && metDeltaThreshold) setScrollDirection(direction)
        else if (!metScrollThreshold) setScrollDirection(0)
      }
    },
    [disabled, deltaThreshold, scrollThreshold, metScrollThreshold]
  )

  return { scrollDirection, metScrollThreshold }
}
