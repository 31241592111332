import { useFloating, useDismiss, useInteractions, autoUpdate } from '@floating-ui/react'
import { useLocation } from '@kaliber/routing'
import { useNormalizeLink } from '/machinery/useNormalizeLink'
import { useTargetAndRel } from '/machinery/useTargetAndRel'
import { useScrollDirection } from '/machinery/useScrollDirection'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { useNavigationContext } from '/features/pageOnly/NavigationContext'
import { useUserInfo } from '/machinery/UserInfoContext'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeaderLogo } from '/features/pageOnly/HeaderLogo'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './MobileNavigation.css'

import chevronDown from '/images/icons/chevron-down.raw.svg'
import iconGlobe from '/images/icons/globe.raw.svg'

export function MobileNavigation({ menu }) {
  const { activeMobileMenu, onActiveMobileMenuChange } = useNavigationContext()
  const { scrollDirection } = useScrollDirection({ deltaThreshold: 50, scrollThreshold: 15 })
  const isHidden = scrollDirection === 1
  const { refs, getReferenceProps } = useFloatingProps({ onOpenChange: onActiveMobileMenuChange })

  return (
    <div className={cx(styles.component, isHidden && styles.isHidden)}>
      <div ref={refs.setReference} className={styles.navbar} {...getReferenceProps()}>
        <ContainerXl>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <span className={styles.logoContainer}>
                <HeaderLogo />
              </span>
              <Hamburger onClick={onActiveMobileMenuChange} isActive={activeMobileMenu} layoutClassName={styles.hamburgerLayout} />
            </div>
          </div>
        </ContainerXl>
        <Menu isActive={activeMobileMenu} layoutClassName={styles.menuLayout} {...{ menu }} />
      </div>
    </div>
  )
}

function Hamburger({ onClick, isActive, layoutClassName = undefined }) {
  return (
    <button className={cx(styles.componentHamburger, layoutClassName)} {...{ onClick }}>
      <span className={styles.hamburgerLabel}>Menu</span>
      <span className={cx(styles.hamburger, isActive && styles.isActive)} />
    </button>
  )
}

function Menu({ menu, isActive, layoutClassName = undefined }) {
  const { isLoggedIn } = useUserInfo()
  const { __ } = useTranslate()
  const { items: menuItems, internalItems } = menu

  const normalizedInternalItems = {
    label: __`internal-menu-links`,
    submenus: [{
      submenuLinks: internalItems
    }]
  }

  return (
    <div className={cx(styles.componentMenu, isActive && styles.isActive, layoutClassName)}>
      <div className={cx(styles.menuContainer, isActive && styles.isActive)}>
        <ul className={styles.menuItems}>
          {menuItems.map((item, i) => <MenuItem key={i} id={`${item._key}_${i}`} {...{ item }} />)}
          {isLoggedIn && <MenuItem id='internal' item={normalizedInternalItems} />}
        </ul>
        <div className={styles.languageAndLoginContainer}>
          <LoginLink />
          <LanguageSwitch />
        </div>
      </div>
    </div>
  )
}

function MenuItem({ id, item }) {
  const { label, link, submenus } = item

  return (
    <li className={styles.componentMenuItem}>
      {submenus?.length
        ? <MenuItemWithDropdown {...{ id, label, link, submenus }} />
        : <MenuItemLink item={link} {...{ label }} />
      }
    </li>
  )
}

function MenuItemLink({ item, label }) {
  const location = useLocation()
  const link = useNormalizeLink({ item, location })

  return (
    <a data-x='link-in-menu' className={styles.componentMenuItemLink} href={link.href} target={link.target}>
      <MenuItemLabel {...{ label }} />
    </a>
  )
}

function MenuItemLabel({ label }) {
  return <span className={styles.componentMenuItemLabel}>{label}</span>
}

function MenuItemWithDropdown({ id, label, link, submenus }) {
  const { activeSubmenu, onActiveSubmenuChange } = useNavigationContext()
  const submenuIsActive = activeSubmenu.active
  const thisSubmenuIsActive = id === activeSubmenu.id && submenuIsActive
  const hasMultipleSubmenus = submenus.length > 1

  return (
    <div className={styles.componentMenuItemWithDropdown}>
      {label && <DropdownToggle onClick={handleActiveSubmenuChange} layoutClassName={styles.dropdownToggleLayout}  {...{ label, thisSubmenuIsActive }} />}
      <Dropdown {...{ submenus, hasMultipleSubmenus, thisSubmenuIsActive }} />
      <span className={cx(styles.dropdownBackground, thisSubmenuIsActive && styles.thisSubmenuIsActive)} />
    </div>
  )

  function handleActiveSubmenuChange() {
    onActiveSubmenuChange({ id, active: thisSubmenuIsActive ? !submenuIsActive : true })
  }
}

function DropdownToggle({ onClick, label, thisSubmenuIsActive, layoutClassName = undefined }) {
  return (
    <button data-x='click-to-open-submenu' className={cx(styles.componentDropdownToggle, layoutClassName)} {...{ onClick }}>
      <MenuItemLabel {...{ label }} />
      <span className={cx(styles.iconContainer, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
        <Icon icon={chevronDown} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function Dropdown({ submenus, hasMultipleSubmenus, thisSubmenuIsActive }) {
  return (
    <div className={cx(styles.componentDropdown, thisSubmenuIsActive && styles.isActive)}>
      <ul className={styles.submenus}>
        {submenus.map((submenu, i) => <Submenu key={i} {...{ submenu, hasMultipleSubmenus }} />)}
      </ul>
    </div>
  )
}


function Submenu({ submenu, hasMultipleSubmenus }) {
  const { submenuLabel: label, submenuLinks } = submenu

  return (
    <li className={styles.componentSubmenu}>
      {hasMultipleSubmenus && <span className={styles.submenuLabel}>{label}</span>}
      <ul className={styles.submenu}>
        {submenuLinks.map((item, i) => <SubmenuLink key={i} {...{ item }} />)}
      </ul>
    </li>
  )
}

function SubmenuLink({ item }) {
  const location = useLocation()
  const link = useNormalizeLink({ item, location })
  const { href, label, target: targetOverride } = link
  const { target, rel } = useTargetAndRel({ targetOverride, href })

  return (
    <li className={styles.componentSubmenuLink}>
      <a data-x='link-in-menu' {...{ href, target, rel }}>{label}</a>
    </li>
  )
}

function LanguageSwitch() {
  const { __ } = useTranslate()
  const { translations } = useNavigationContext()

  return (
    <div className={styles.componentLanguageSwitch}>
      <span className={styles.languageSwitchLabel}>
        <Icon icon={iconGlobe} layoutClassName={styles.iconLayout} />
        {__`language`}
      </span>
      <ul className={styles.languageSwitchLinksContainer}>
        {translations.map(({ language, href, current }, i) => (
          <LanguageSwitchLink
            key={i}
            label={__`language-translation-label-${language}`}
            isActive={current}
            target='_self'
            ariaLabel={current
              ? `${__`language-current`}: ${__`language-translation-label-${language}`}`
              : __`language-translation-label-${language}`
            }
            hrefLang={language}
            {...{ href }}
          />
        ))}
      </ul>
    </div>
  )
}

function LanguageSwitchLink({ label, isActive, target, ariaLabel, href, hrefLang }) {
  return (
    <li data-x='click-language-switch' className={cx(styles.componentLanguageSwitchLink, isActive && styles.isActive)}>
      <a className={styles.languageSwitchLink} aria-label={ariaLabel} {...{ target, href, hrefLang }}>{label}</a>
    </li>
  )
}

function LoginLink() {
  const { __ } = useTranslate()
  const location = useLocation()
  const userInfo = useUserInfo()
  const { isLoggedIn } = userInfo

  const queryString = new URLSearchParams({ returnPath: location.pathname }).toString()
  const loginUrl = `${routeMap.api.v1.auth.login()}?${queryString}`
  const logoutUrl = `${routeMap.api.v1.auth.logout()}?${queryString}`

  return (
    <div className={styles.componentLoginLink}>
      {isLoggedIn
        ? <a href={logoutUrl} target='_self' className={styles.loginLink}>{__`btn-logout`}</a>
        : <a href={loginUrl} target='_self' className={styles.loginLink}>{__`btn-login`}</a>
      }
    </div>
  )
}

function useFloatingProps({ onOpenChange }) {
  const { activeMobileMenu } = useNavigationContext()

  const { refs, context } = useFloating({
    open: activeMobileMenu,
    onOpenChange,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: true
  })

  const { getReferenceProps } = useInteractions([dismiss])

  return { refs, getReferenceProps }
}
