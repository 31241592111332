import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './HeaderLogo.css'

import logo from '/images/logos/KLM.raw.svg'

export function HeaderLogo() {
  const language = useLanguage()

  return (
    <a aria-label='link to home' data-x='link-in-menu-to-home' href={routeMap.app.home({ language })} className={styles.component}>
      <Icon icon={logo} layoutClassName={styles.logoLayout} />
    </a>
  )
}
