import { useMediaQuery } from '@kaliber/use-media-query'
import { useLocation } from '@kaliber/routing'
import { useTranslate } from '/machinery/I18n'
import { useNormalizeLink } from '/machinery/useNormalizeLink'
import { useTargetAndRel } from '/machinery/useTargetAndRel'
import { routeMap } from '/routeMap'
import { useUserInfo } from '/machinery/UserInfoContext'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { useNavigationContext } from '/features/pageOnly/NavigationContext'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingMd, HeadingLg } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './DesktopNavigationSubmenu.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function DesktopNavigationSubmenu({ submenu, thisSubmenuIsActive, offsetLeftSubmenuItem }) {
  const { submenuLabel: label, submenuLinks } = submenu

  return (
    <li className={cx(styles.component, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
      <ContainerXl>
        <div style={{ '--offset-submenu': `${offsetLeftSubmenuItem}px` }} className={styles.submenuContainer}>
          <SubmenuHeading layoutClassName={styles.submenuHeadingLayout} {...{ label }} />
          <ul className={styles.submenu}>
            {submenuLinks.map((item, i) => <SubmenuLinkFromCMS key={i} {...{ item }} />)}
          </ul>
        </div>
      </ContainerXl>
    </li>
  )
}

export function DesktopNavigationSubmenuLanguageSwitch({ label, icon, thisSubmenuIsActive, offsetLeftSubmenuItem }) {
  const { __ } = useTranslate()
  const { translations } = useNavigationContext()

  return (
    <li className={cx(styles.componentLanguageSwitch, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
      <ContainerXl>
        <div style={{ '--offset-submenu': `${offsetLeftSubmenuItem}px` }} className={styles.submenuContainer}>
          <SubmenuHeading layoutClassName={styles.submenuHeadingLayout} {...{ label, icon }} />
          <ul className={styles.submenu}>
            {translations.map(({ language, href, current }, i) => (
              <SubmenuLink
                key={i}
                label={__`language-translation-label-${language}`}
                target='_self'
                ariaLabel={current
                  ? `${__`language-current`}: ${__`language-translation-label-${language}`}`
                  : __`language-translation-label-${language}`
                }
                hrefLang={language}
                isActive={current}
                {...{ href }}
              />
            ))}
          </ul>
        </div>
      </ContainerXl>
    </li>
  )
}

export function DesktopNavigationSubmenuLogin({ label, thisSubmenuIsActive, offsetLeftSubmenuItem, internalItems }) {
  const { __ } = useTranslate()
  const location = useLocation()
  const userInfo = useUserInfo()
  const { isLoggedIn } = userInfo || {}

  const queryString = new URLSearchParams({ returnPath: location.pathname }).toString()
  const loginUrl = `${routeMap.api.v1.auth.login()}?${queryString}`
  const logoutUrl = `${routeMap.api.v1.auth.logout()}?${queryString}`

  return (
    <li className={cx(styles.componentLogin, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
      <ContainerXl>
        <div style={{ '--offset-submenu': `${offsetLeftSubmenuItem}px` }} className={styles.submenuContainer}>
          <SubmenuHeading layoutClassName={styles.submenuHeadingLayout} {...{ label }} />

          <ul className={cx(styles.submenu, styles.loginSubmenu)}>

            {isLoggedIn && internalItems?.map(({ _type, label, ref, href, _key }) => {
              if (_type === 'internalLink')
                return <SubmenuLink key={_key} href={determineDocumentPathSync({ document: ref, routeMap })} target='_self' {...{ label }}  />
              if (_type === 'externalLink')
                return <SubmenuLink key={_key}  target='_blank' {...{ label, href }}  />
              return null
            })}

            {isLoggedIn
              ? <SubmenuLink href={logoutUrl} target='_self' label={__`btn-logout`}  />
              : <SubmenuLink href={loginUrl} target='_self' label={__`btn-login`} />
            }
          </ul>
        </div>

      </ContainerXl>
    </li>
  )
}

function SubmenuHeading({ label, icon = undefined, layoutClassName = undefined }) {
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl) ?? false

  return (
    <div className={cx(styles.componentSubmenuHeading, layoutClassName)}>
      {isViewportXl
        ? <HeadingLg h='4' layoutClassName={styles.submenuHeadingLayout}>{label}</HeadingLg>
        : <HeadingMd h='4' layoutClassName={styles.submenuHeadingLayout}>{label}</HeadingMd>
      }
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
    </div>
  )
}

function SubmenuLinkFromCMS({ item }) {
  const location = useLocation()
  const { href, label, target, isActive } = useNormalizeLink({ item, location })

  return <SubmenuLink {...{ href, label, target, isActive }} />
}

function SubmenuLink({ href, target: targetOverride, label, isActive = undefined, ariaLabel = undefined, hrefLang = undefined }) {
  const { target, rel } = useTargetAndRel({ targetOverride, href })

  return (
    <li className={styles.componentSubmenuLink}>
      <span className={styles.arrowIconContainer}>
        <Icon icon={arrowIcon} layoutClassName={styles.arrowIconLayout} />
      </span>
      <a data-x='link-in-menu' className={styles.submenuLink} aria-label={ariaLabel} {...{ href, hrefLang, target, rel }}>
        <span data-content={label} className={cx(styles.submenuLinkLabel, isActive && styles.isActive)}>{label}</span>
      </a>
    </li>
  )
}
