import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useMergeRefs } from '@floating-ui/react'
import { useLocation } from '@kaliber/routing'
import { useTranslate } from '/machinery/I18n'
import { useNormalizeLink } from '/machinery/useNormalizeLink'
import { useScrollDirection } from '/machinery/useScrollDirection'
import { useGetBoundingClientRect } from '/machinery/useGetBoundingClientRect'

import { useNavigationContext } from '/features/pageOnly/NavigationContext'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeaderLogo } from '/features/pageOnly/HeaderLogo'
import { Icon } from '/features/buildingBlocks/Icon'
import {
  DesktopNavigationSubmenu                as Submenu,
  DesktopNavigationSubmenuLanguageSwitch  as LanguageSwitch,
  DesktopNavigationSubmenuLogin           as Login
} from '/features/pageOnly/DesktopNavigationSubmenu'

import styles from './DesktopNavigation.css'

import iconGlobe from '/images/icons/globe.raw.svg'
import iconChevronDown from '/images/icons/chevron-down-fill.raw.svg'
import { useUserInfo } from '/machinery/UserInfoContext'

export function DesktopNavigation({ menu, heroType }) {
  const { __ } = useTranslate()
  const { isLoggedIn, claims } = useUserInfo()
  const { givenName = '' } = claims || {}
  const { classNames, handleMenuHover } = useHeaderScolling()
  const headerStyleClassName = useHeaderStyleClassName({ heroType })

  const { items: menuItems, internalItems } = menu
  const { x: offsetLeftMenuContainer, elementRef } = useGetBoundingClientRect()

  return (
    <div className={cx(styles.component, headerStyleClassName, ...classNames)}>
      <ContainerXl>
        <div className={styles.navigationBarWrapper}>
          <div ref={elementRef} className={styles.navigationBarContainer}>

            <span className={styles.logoContainer}>
              <HeaderLogo />
            </span>

            <div onMouseEnter={() => handleMenuHover(true)} onMouseLeave={() => handleMenuHover(false)} className={styles.menuContainer}>
              {menuItems && <Menu {...{ menuItems, offsetLeftMenuContainer }} />}

              <span className={styles.menuSeperator} />

              <div className={styles.languageAndLoginContainer}>
                <MenuItemWithDropdown id='language' label={__`language`} icon={iconGlobe} SubmenuComponent={LanguageSwitch} {...{ offsetLeftMenuContainer }} />
                <MenuItemWithDropdown id='login' label={isLoggedIn ? `${__`welcome`} ${givenName}` : __`login`} icon={iconChevronDown} SubmenuComponent={x => Login({ ...x, internalItems })} {...{ offsetLeftMenuContainer }} />
              </div>
            </div>

          </div>
        </div>
      </ContainerXl>
      <Background layoutClassName={styles.backgroundLayout} />
    </div>
  )
}

function Background({ layoutClassName = undefined }) {
  const { activeSubmenu } = useNavigationContext()
  const { active, submenuHeight } = activeSubmenu
  const scaleY = active
    ? (100 - submenuHeight) / 100 * -1 + 1
    : 0

  return <span style={{ '--submenu-background-scaleY': scaleY }} className={cx(styles.componentBackground, layoutClassName)} />
}

function Menu({ menuItems, offsetLeftMenuContainer }) {
  return (
    <ul className={styles.componentMenu}>
      {menuItems.map((item, i) => (
        <MenuItem key={i} id={`${item._key}_${i}`} {...{ item, offsetLeftMenuContainer }} />
      ))}
    </ul>
  )
}

function MenuItem({ id, item, offsetLeftMenuContainer, layoutClassName = undefined }) {
  const { label, link, submenus } = item

  return (
    <li className={cx(styles.componentMenuItem, layoutClassName)}>
      {submenus?.length
        ? <MenuItemWithDropdown {...{ id, label, link, submenus, offsetLeftMenuContainer }} />
        : <MenuItemLink item={link} {...{ label }} />
      }
    </li>
  )
}

function MenuItemLink({ label, item }) {
  const location = useLocation()
  const { href, target, isActive } = useNormalizeLink({ item, location })

  return (
    <a data-x='link-in-menu' className={cx(styles.componentMenuItemLink, isActive && styles.isActive)} {...{ href, target }}>
      <MenuItemLabel layoutClassName={styles.menuLabelLayout} {...{ label }} />
    </a>
  )
}

function MenuItemLabel({ label, dataX = undefined, icon = undefined, layoutClassName = undefined }) {
  return (
    <span data-x={dataX} className={cx(styles.componentMenuItemLabel, layoutClassName)}>
      {label}
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
    </span>
  )
}

function MenuItemWithDropdown({ id, label, offsetLeftMenuContainer, link = undefined, icon = undefined, submenus = undefined, SubmenuComponent = undefined }) {
  const { activeSubmenu } = useNavigationContext()
  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.active
  const { x: offsetLeftMenuItem, elementRef: menuLabelRef } = useGetBoundingClientRect()
  const { height: submenuHeight, elementRef: submenuRef } = useGetBoundingClientRect()
  const { refs, getReferenceProps, getFloatingProps, style } = useFloatingProps({ id, submenuHeight })
  const offsetLeftSubmenuItem = offsetLeftMenuItem - offsetLeftMenuContainer

  return (
    <div ref={refs.setReference} {...getReferenceProps()} className={cx(styles.componentMenuItemWithDropdown, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
      {label && <DropdownLabelRef ref={menuLabelRef} {...{ label, link, icon }} />}
      <ul ref={useMergeRefs([refs.setFloating, submenuRef])} {...getFloatingProps()} className={styles.dropdown} {...{ style }}>
        {submenus
          ? (
            submenus.map((submenu, i) => (
              <Submenu key={i} {...{ submenu, thisSubmenuIsActive, offsetLeftSubmenuItem }} />
            ))
          ) : SubmenuComponent && (
            <SubmenuComponent {...{ label, icon, thisSubmenuIsActive, offsetLeftSubmenuItem }} />
          )}
      </ul>
    </div>
  )
}

const DropdownLabelRef = React.forwardRef(DropdownLabel)

function DropdownLabel({ label, link, icon }, ref) {
  return (
    <span className={styles.componentDropdownLabel} {...{ ref }}>
      {link
        ? <MenuItemLink item={link} {...{ label }} />
        : <MenuItemLabel layoutClassName={styles.menuLabelLayout} dataX='hover-to-open' {...{ label, icon }} />
      }
    </span>
  )
}

function useHeaderScolling() {
  const [isHovering, setIsHovering] = React.useState(false)
  const { submenuIsOpen } = useNavigationContext()
  const open = submenuIsOpen || isHovering
  const scrollThreshold = 15

  const { scrollDirection, metScrollThreshold } = useScrollDirection({ deltaThreshold: 50, scrollThreshold, disabled: open })

  const scrollingUp = scrollDirection === -1
  const neutralScrolling = scrollDirection === 0

  const initialState = React.useMemo(
    () => {
      if (scrollDirection !== 0) {
        return false
      } else if (window.scrollY > scrollThreshold) {
        return true
      } else {
        return false
      }
    },
    [scrollDirection]
  )

  const isScrolledDown = metScrollThreshold && scrollingUp
  const isSolid = isScrolledDown || open || initialState
  const isVisible = scrollingUp || neutralScrolling

  const classNames = [
    isScrolledDown && styles.isScrolledDown,
    isSolid && styles.isSolid,
    isVisible && styles.isVisible
  ]

  function handleMenuHover(x) {
    setIsHovering(x)
  }

  return { classNames, handleMenuHover }
}

function useHeaderStyleClassName({ heroType }) {
  switch (heroType) {
    case 'white': return styles.headerWhite
    case 'blue': return styles.headerTransparentDarkBlue
    case 'lightBlue': return styles.headerTransparent
    case 'darkBlue': return styles.headerTransparentWhite
    case 'error': return styles.headerTransparentWhite
    default: return styles.headerWhite
  }
}

function useFloatingProps({ id, submenuHeight }) {
  const { submenuIsOpen, onActiveSubmenuChange } = useNavigationContext()

  const { x, y, strategy, refs, context } = useFloating({
    open: submenuIsOpen,
    onOpenChange: handleActiveSubmenuChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift({ padding: 0 })]
  })

  const style = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0
  }

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: false
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss ])

  useHover(context)

  function handleActiveSubmenuChange(x) {
    onActiveSubmenuChange({ id, active: x, submenuHeight })
  }

  return { refs, getReferenceProps, getFloatingProps, style }
}
