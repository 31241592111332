import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'

const NavigationContext = React.createContext(null)

export function NavigationContextProvider({ translations, children }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const [activeSubmenu, setActiveSubmenu] = React.useState({ id: undefined, active: false, submenuHeight: 0 })
  const submenuIsOpen = activeSubmenu.active
  const [activeMobileMenu, setActiveMobileMenu] = React.useState(false)

  React.useEffect(
    () => {
      setActiveMobileMenu(false)
      setActiveSubmenu(prevState => ({ ...prevState, active: false }))
    },
    [isViewportLg]
  )

  return (
    <NavigationContext.Provider
      value={{
        translations,
        submenuIsOpen,
        activeSubmenu,
        onActiveSubmenuChange,
        activeMobileMenu,
        onActiveMobileMenuChange
      }}
      {...{ children }}
    />
  )

  function onActiveSubmenuChange({ id, active, submenuHeight }) {
    setActiveSubmenu({ id, active, submenuHeight })
  }

  function onActiveMobileMenuChange() {
    setActiveMobileMenu(!activeMobileMenu)
    setActiveSubmenu(prevState => ({ ...prevState, active: false }))
  }
}

export function useNavigationContext() {
  return React.useContext(NavigationContext)
}
