import { FloatingOverlay } from '@floating-ui/react'
import { useSpring, animated } from '@react-spring/web'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'
import { useLocation } from '@kaliber/routing'
import { useLanguage } from '/machinery/I18n'

import { NavigationContextProvider, useNavigationContext } from '/features/pageOnly/NavigationContext'
import { DesktopNavigation } from '/features/pageOnly/DesktopNavigation'
import { MobileNavigation } from '/features/pageOnly/MobileNavigation'
import { ContainerXl } from '/features/buildingBlocks/Container'

import styles from './Header.css'

export function Header({ menu, translations, heroType, layoutClassName = undefined }) {
  return (
    <header className={cx(styles.component, layoutClassName)}>
      <NavigationContextProvider {...{ translations }}>
        <Navigation {...{ menu, heroType }} />
      </NavigationContextProvider>
    </header>
  )
}

function Navigation({ menu, heroType }) {
  const isMounted = useRenderOnMount()
  const location = useLocation()
  const language = useLanguage()
  const isOnHomePage = location.pathname === `/${language}/`
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const { submenuIsOpen, activeMobileMenu } = useNavigationContext()
  const open = submenuIsOpen || activeMobileMenu

  const { y } = useSpring({
    delay: 1000,
    from: { y: isOnHomePage ? -80 : 0 },
    to: { y: 0 },
    config: { mass: 1, tension: 75, friction: 25 },
  })

  return (
    // eslint-disable-next-line @kaliber/layout-class-name
    <FloatingOverlay lockScroll={activeMobileMenu} className={cx(styles.componentNavigation, open && styles.open)}>
      {isMounted && (
        <animated.div className={styles.navigationContainer} style={{ y }}>
          {isViewportLg
            ? <DesktopNavigation {...{ menu, heroType }} />
            : <ContainerXl><MobileNavigation {...{ menu }} /></ContainerXl>
          }
        </animated.div>
      )}
    </FloatingOverlay>
  )
}
